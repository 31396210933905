import React, { useEffect } from 'react';
// import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

type Props = {};

const ErrorRedirect = (props: Props) => {
  // const location = useLocation();

  const trimLastPath = path => {
    // Find the last index of "/"
    const lastIndex = path.lastIndexOf('/');

    // If lastIndex is -1, it means there is no "/" in the path
    if (lastIndex === -1) {
      return '/';
    }

    // Extract the substring up to the last index
    const trimmedPath = path.substring(0, lastIndex + 1);
    return trimmedPath;
  };
  useEffect(() => {
    navigate(trimLastPath(window.location.pathname));
  }, []);

  return <div></div>;
};

export default ErrorRedirect;
